const isPhase2 = () => {
  const today = new Date();
  return today > new Date('2022/05/25 12:00') && today < new Date('2022/06/08');
};
const isPhase3 = () => {
  const today = new Date();
  return today > new Date('2022/06/08 21:00');
};
const isRegisterFinalCall = () => {
  const today = new Date();
  return today > new Date('2022/06/08 21:00');
};
const isPhase4 = () => {
  const today = new Date();
  return today > new Date('2022/06/19 21:00');
};
const isPhase5 = () => {
  const today = new Date();
  // return true;
  return today > new Date('2022/06/15 12:00');
};
const isPhase6 = () => {
  const today = new Date();
  return today > new Date('2022/06/28');
};
const getMintNowURL = () => {
  // for testing
  // if (!(new Date() > new Date('2022/06/19 21:00'))) {
  if (new Date() > new Date('2022/06/19 21:00')) {
    //official date
    return 'https://marketplace.artzioneer.com/';
  }
  if (isPhase4()) {
    // Phase 4 case
    return process.env.REACT_APP_MINT_NOW_PHASE4_URL;
  } else {
    // Phase 3 case
    return process.env.REACT_APP_MINT_NOW_PHASE3_URL;
  }
};
export { isPhase2, isPhase3, isPhase4, isPhase5, isPhase6, isRegisterFinalCall, getMintNowURL };
