import './Timeline.scss';
interface TimeLineListItem {
  leftText: string;
  rightText: string;
}
interface ITimeline {
  list: Array<TimeLineListItem>;
}
const Timeline = ({ list = [] }: ITimeline) => {
  return (
    <div className={'timelineContainer'} >
      <div
        style={{
          borderLeft: '1px dashed orange',
          position: 'absolute',
          transform: 'translateX(50%)',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {list.map((el, key) => {
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: 10,
                paddingTop: 10,
                width: '80%',
              }}
            >
              <span
                style={{ width: '45%', textAlign: 'right', fontWeight: 700, fontSize: '30px', lineHeight: '45px' }}
                dangerouslySetInnerHTML={{
                  __html: el.leftText,
                }}
              ></span>
              <div
                style={{
                  minWidth: '26px',
                  width: '5%',
                  height: '1px',
                  backgroundColor: 'orange',
                  margin: '0px 20px',
                }}
              ></div>
              <span
                style={{ width: '45%', textAlign: 'left', fontWeight: 700, fontSize: '18px', lineHeight: '27px' }}
                dangerouslySetInnerHTML={{
                  __html: el.rightText,
                }}
              ></span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Timeline;
