import { useTranslation } from 'react-i18next';
import Marquee from '../../components/Marquee/Marquee';

const StoryBackground = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        backgroundImage: "url('/bg_StoryBackground.png')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: '100px',
      }}
      id="storyBackground"
    >
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: 50,
        }}
      >
        {t('topbar.storyBackground')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          paddingBottom: 30,
          margin: '0px 10%',
        }}
      >
        {t('storyBackground.description1')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          paddingBottom: 30,
          margin: '0px 10%',
        }}
      >
        {t('storyBackground.description2')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          paddingBottom: 30,
          margin: '0px 10%',
        }}
      >
        {t('storyBackground.description3')}
      </span>
      <img src={'/bg_StoryBackground_Planet.png'} style={{ width: '80%' }} />
    </div>
  );
};

export default StoryBackground;
