interface IDivider {
  width?: string;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  backgroundColor?: string;
}
const Divider = ({
  width = '1px',
  height = '80%',
  marginLeft = '20px',
  marginRight = '20px',
  backgroundColor = 'rgba(255, 255, 255, 0.2)',
}: IDivider) => {
  return <div style={{ backgroundColor: backgroundColor, width: width, height: height, marginLeft: marginLeft, marginRight: marginRight }}></div>;
};

export default Divider;
