import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import Divider from '../Divider/Divider';
import { LocalesKey } from '../../language';
import { Box, Drawer, IconButton, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './TopBar.scss';
import { useNavigate } from 'react-router-dom';
import { getMintNowURL, isPhase3, isPhase4, isPhase5, isPhase6, isRegisterFinalCall } from '../../utils/checkPhase';

const TopBar = (props: { isRegistration: boolean }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(false);
  const sections = [
    { anchorId: '/#storyBackground', displayName: t('topbar.storyBackground') },
    { anchorId: '/#whitelistingArrangement', displayName: t('topbar.whitelistingArrangement') },
    { anchorId: '/#mintingArrangement', displayName: t('topbar.mintingArrangement') },
    { anchorId: '/#auctionRelated', displayName: t('topbar.auctionRelated') },
    { anchorId: '/#roadMap', displayName: t('topbar.roadMap') },
    { anchorId: '/#holderBenefit', displayName: t('topbar.holderBenefit') },
    { anchorId: '/#aboutPlanetS', displayName: t('topbar.aboutPlanetS') },
    { anchorId: '/#faqs', displayName: t('topbar.faqs') },
  ].filter((el) => {
    if (el.anchorId === '/#auctionRelated' && !isPhase5()) {
      return false;
    } else {
      return true;
    }
  });

  const mintNowUrl = getMintNowURL();
  const showMintNow = isPhase3() || isPhase4() || isPhase5() || isPhase6();
  const showMintSoon = new Date() > new Date('2022/06/19 12:00') && new Date() < new Date('2022/06/19 21:00'); //official
  // const showMintSoon = true || (new Date() > new Date('2022/06/19 12:00') && new Date() < new Date('2022/06/19 21:00')); // for testing

  const handleScrollToElement = (el: HTMLElement) => {
    var headerOffset = 64;
    var elementPosition = el.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const DrawerEl = (
    <Drawer anchor={'right'} open={isDrawerExpanded} onClose={() => setIsDrawerExpanded(false)}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '350px', height: '100%', background: '#000000' }}>
        <img src={'/tamjaiInternationalLogo.png'} style={{ width: '141px', padding: '40px 20px 20px' }} />
        {sections &&
          sections.map((el) => {
            return (
              <HashLink
                key={el.anchorId}
                style={{ textDecoration: 'none' }}
                scroll={(el) => {
                  setIsDrawerExpanded(false);
                  handleScrollToElement(el);
                }}
                to={el.anchorId}
              >
                <Box
                  sx={{
                    color: '#ffffff',
                    padding: '20px',
                    cursor: 'pointer',
                    ':hover': {
                      color: '#ffffff80',
                    },
                  }}
                >
                  {el.displayName}
                </Box>
              </HashLink>
            );
          })}
      </div>
    </Drawer>
  );
  return (
    <AppBar position="sticky" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          {DrawerEl}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ paddingRight: 20 }}>
              <img src={'/tamjaiInternationalLogo.png'} className={'tamjaiInternationalLogo'} alt="logo" />
            </div>
            <div
              // style={{ display: 'flex', alignItems: 'center' }}
              className="menuList"
            >
              {!props.isRegistration &&
                sections &&
                sections.map((el) => (
                  <HashLink
                    key={el.anchorId}
                    style={{ textDecoration: 'none' }}
                    // scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    scroll={(el) => {
                      handleScrollToElement(el);
                    }}
                    to={el.anchorId}
                  >
                    <Button variant="text" sx={{ color: 'white', textTransform: 'capitalize' }}>
                      {el.displayName}
                    </Button>
                  </HashLink>
                ))}
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              className={'langENBtn'}
              variant="text"
              sx={{ color: 'white' }}
              onClick={() => {
                i18n.changeLanguage(LocalesKey.EN);
              }}
            >
              EN
            </Button>
            <Divider height="10px" marginLeft="0px" marginRight="0px" backgroundColor="#ffffff" />
            <Button
              className={'langZHBtn'}
              variant="text"
              sx={{ color: 'white' }}
              onClick={() => {
                i18n.changeLanguage(LocalesKey.ZH_HK);
              }}
            >
              繁
            </Button>
            {showMintSoon ? (
              // <Link href={mintNowUrl} sx={{ textDecoration: 'none' }}>
              <Button
                className={'mintSoonBtn'}
                disableRipple
                size={'large'}
                sx={{
                  backgroundColor: '#777777',
                  color: '#ffffff50',
                  borderRadius: '40px',
                  textTransform: 'capitalize',
                  minWidth: '100px',
                  fontWeight: 700,
                  cursor: 'not-allowed',
                  ':hover': {
                    backgroundColor: '#777777',
                    color: '#ffffff50',
                  },
                }}
              >
                {t('topbar.mintSoon')}
              </Button>
            ) : (
              // </Link>
              <Link href={mintNowUrl} sx={{ textDecoration: 'none' }}>
                <Button
                  className={'mintSoonBtn'}
                  disableRipple
                  size={'large'}
                  sx={{
                    backgroundColor: '#94111C',
                    color: '#ffffff',
                    borderRadius: '40px',
                    textTransform: 'capitalize',
                    minWidth: '100px',
                    cursor: 'pointer',
                    fontWeight: 700,
                    ':hover': {
                      bgcolor: '#94111C',
                      color: '#ffffff',
                    },
                  }}
                >
                  {t('topbar.mintNow')}
                </Button>
              </Link>
            )}
            {/* {!props.isRegistration && (
              <>
                {showMintNow ? (
                  <Link href={mintNowUrl} sx={{ textDecoration: 'none' }}>
                    <Button
                      className={'mintSoonBtn'}
                      disableRipple
                      size={'large'}
                      sx={{
                        backgroundColor: '#94111C',
                        color: '#ffffff',
                        borderRadius: '40px',
                        textTransform: 'capitalize',
                        minWidth: '100px',
                        cursor: 'pointer',
                        fontWeight: 700,
                        ':hover': {
                          bgcolor: '#94111C',
                          color: '#ffffff',
                        },
                      }}
                    >
                      {t('topbar.mintNow')}
                    </Button>
                  </Link>
                ) : (
                  <Link
                    href={isRegisterFinalCall() ? process.env.REACT_APP_REGISTER_NOW_FINAL_URL : process.env.REACT_APP_REGISTER_NOW_PUBLIC_URL}
                    sx={{ textDecoration: 'none' }}
                  >
                    <Button
                      className={'mintSoonBtn'}
                      disableRipple
                      size={'large'}
                      sx={{
                        backgroundColor: '#94111C',
                        color: '#ffffff',
                        borderRadius: '40px',
                        textTransform: 'capitalize',
                        minWidth: '100px',
                        fontWeight: 700,
                        ':hover': {
                          bgcolor: '#94111C',
                          color: '#ffffff80',
                        },
                      }}
                    >
                      {t('topbar.registerNow')}
                    </Button>
                  </Link>
                )}
              </>
            )} */}
            {!props.isRegistration && (
              <IconButton
                className={'menuIcon'}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ marginLeft: '20px' }}
                onClick={() => setIsDrawerExpanded((prev) => !prev)}
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopBar;
