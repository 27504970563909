import { Button, Checkbox, CircularProgress, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Divider from './components/Divider/Divider';
import TopBar from './components/TopBar/TopBar';
import Footer from './components/Footer/Footer';
import { isBoolean, isString } from 'lodash';
import { checkEmailIsExist, nftRegistration } from './services/register';
import { isRegisterFinalCall } from './utils/checkPhase';
import ReCAPTCHA from 'react-google-recaptcha';
import './Registration.scss';

type BrandType = 'tamjai' | 'samgor' | 'tamjai_and_samgor' | null;
interface RegistrationForm {
  brand: BrandType;
  isMember: boolean | null;
  agreeTnc: boolean | null;
  emailAddress: string;
  captcha: string | null;
}

const Registration = () => {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState<RegistrationForm>({ brand: null, isMember: null, agreeTnc: null, emailAddress: '', captcha: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailAddressExist, setIsEmailAddressExist] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isResultSuccess, setIsResultSuccess] = useState<boolean | undefined>(undefined);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(false);
  const [show2RoundReg, setShow2RoundReg] = useState(false);
  const urlRef = searchParams.get('ref') as string;

  useEffect(()=>{
    if(urlRef){
      setShow2RoundReg(urlRef.indexOf('tysonyoshi') > -1 || urlRef.indexOf('masterseven') > -1 || urlRef.indexOf('public') > -1  || urlRef.indexOf('final') > -1)
    } else {
      setShow2RoundReg(false);
    }
  }, [urlRef]);
  
  const handleChangeBrand = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, brand: e.target.value as BrandType }));
  };
  const handleChangeIsMember = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'true') {
      setForm((prev) => ({ ...prev, isMember: true }));
    } else {
      setForm((prev) => ({ ...prev, isMember: false }));
    }
  };
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm((prev) => ({ ...prev, emailAddress: e.target.value }));
  };
  const handleChangeIsAgreeTnc = (e: SyntheticEvent<Element, Event>, checked: boolean) => {
    setForm((prev) => ({ ...prev, agreeTnc: checked }));
  };

  const checkFormIsValid = () => {
    if (!(form.brand === 'tamjai' || form.brand === 'samgor' || form.brand === 'tamjai_and_samgor')) {
      return false;
    }
    if (!isBoolean(form.isMember)) {
      return false;
    }
    if (!/^[a-zA-z0-9+]+([\.-]?[a-zA-z0-9+]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.emailAddress)) {
      return false;
    }
    if (form.agreeTnc !== true) {
      return false;
    }
    if (captchaValue !== true) {
      return false;
    }
    return true;
  };

  const recaptchaChange = (value: string | null) => {
    // console.log('Captcha value:');
    // console.log(value);
    if (value) {
      setCaptchaValue(true);
    } else {
      setCaptchaValue(false);
    }
    setForm((prev) => ({ ...prev, captcha: value }));
  };

  const handleCheckEmailIsExistFromBN = async () => {
    if (!/^\w+([\.-]?[\w+]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.emailAddress)) {
      console.log('Invalid email address.');
      setIsInvalidEmail(true);
      return;
    } else {
      setIsInvalidEmail(false);
    }

    const postData = {
      emailAddress: form.emailAddress,
    };
    const response: any = await checkEmailIsExist(postData);
    if (response && response.status === 200) {
      setIsEmailAddressExist(response.data.status);
    } else {
      setIsEmailAddressExist(false);
    }
    // console.log('handleCheckEmailFromBN response', response);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const postData = {
      ...form,
      ref: typeof searchParams.get('ref') === 'string' && searchParams.get('ref') !== '' ? searchParams.get('ref') : null,
      lang: i18n.language === 'en' ? 'en' : 'zh',
    };
    // console.log('handleSubmit form', form);
    // console.log('handleSubmit postData', postData);

    const response: any = await nftRegistration(postData);

    if (response && response.status === 201) {
      setIsResultSuccess(response.data.message === 'Successfully');
    } else {
      setIsResultSuccess(false);

      if (response.response.data.errorMessage === 'recaptcha Error') {
        setSubmitErrorMessage(t('registration.googleRecaptchaError'));
      } else {
        setSubmitErrorMessage(response.response.data.errorMessage);
      }
    }
    setIsSubmitting(false);
    window.scrollTo(0, 0);
  };

  const FormEl = (
    <div className={'registrationForm'}>
      <span style={{ color: '#ffffff', fontWeight: 500, fontSize: '14px', lineHeight: '21px' }}>{t('registration.question1')}</span>

      <RadioGroup row value={form.brand} onChange={handleChangeBrand} sx={{ marginBottom: '20px' }}>
        <FormControlLabel
          value="tamjai"
          control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#94111C' } }} />}
          label={t('registration.question1Option1')}
        />
        <FormControlLabel
          value="samgor"
          control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#94111C' } }} />}
          label={t('registration.question1Option2')}
        />
        <FormControlLabel
          value="tamjai_and_samgor"
          control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#94111C' } }} />}
          label={t('registration.question1Option3')}
        />
      </RadioGroup>

      <span style={{ color: '#ffffff', fontWeight: 500, fontSize: '14px', lineHeight: '21px' }}>{t('registration.question2')}</span>
      <RadioGroup row onChange={handleChangeIsMember} sx={{ marginBottom: '20px' }}>
        <FormControlLabel
          value={'true'}
          control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#94111C' } }} />}
          label={t('registration.question2Option1')}
        />
        <FormControlLabel
          value={'false'}
          control={<Radio sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#94111C' } }} />}
          label={t('registration.question2Option2')}
        />
      </RadioGroup>

      <span style={{ color: '#ffffff', fontWeight: 500, fontSize: '14px', lineHeight: '21px', marginBottom: '8px' }}>
        {t('registration.emailAddress')}
      </span>
      <TextField
        variant="outlined"
        sx={{ backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '5px' }}
        placeholder={t('registration.emailAddressPlaceholder')}
        value={form.emailAddress}
        onChange={handleChangeEmail}
        onBlur={handleCheckEmailIsExistFromBN}
      />
      {isInvalidEmail && <div style={{ marginTop: '5px', marginBottom: '20px', color: 'red' }}>{t('registration.emailError')}</div>}
      {!isInvalidEmail && isEmailAddressExist && (
        <div style={{ marginTop: '5px', marginBottom: '20px', color: 'yellow' }}>{t('registration.emailAddressExistError')}</div>
      )}
      <FormControlLabel
        sx={{ margin: '20px 0px' }}
        control={<Checkbox sx={{ color: '#FFFFFF', '&.Mui-checked': { color: '#94111C' } }} />}
        label={
          <span>
            {t('registration.agreeTo')}
            <Link
              target="_blank"
              href={i18n.language === 'en' ? 'https://www.artzioneer.com/tnc/' : 'https://www.artzioneer.com/zh/tnc/'}
              style={{ color: '#ffffff' }}
            >
              {t('registration.terms')}
            </Link>
          </span>
        }
        onChange={handleChangeIsAgreeTnc}
      />

      <ReCAPTCHA
        hl={i18n.language === 'en' ? 'en' : 'zh-HK'}
        style={{ marginBottom: '40px' }}
        sitekey={process.env.REACT_APP_RE_CAPTCHA as string}
        onChange={recaptchaChange}
      />

      {!isResultSuccess && <div style={{ marginTop: '5px', marginBottom: '20px', color: 'red' }}>{submitErrorMessage}</div>}

      <div style={{ marginBottom: '25px', marginTop: '-30px' }}>{t('registration.emailRemark')}</div>

      <Button
        disabled={!checkFormIsValid() || isSubmitting || isEmailAddressExist}
        sx={{
          borderRadius: '40px',
          backgroundColor: 'rgba(148, 17, 28, 1)',
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '24px',
          paddingTop: '16px',
          paddingBottom: '16px',
          ':hover': {
            backgroundColor: 'rgba(148, 17, 28, 1)',
          },
          ':disabled': {
            backgroundColor: 'rgba(152, 152, 152, 1)',
            color: 'rgba(109, 109, 109, 1)',
          },
        }}
        onClick={handleSubmit}
      >
        {t('registration.submit')}
        {isSubmitting && <CircularProgress color="inherit" size={18} sx={{ marginLeft: '10px' }} />}
      </Button>
    </div>
  );
  const SuccessEl = (
    <div className={'registrationSuccessContainer'}>
      <img src={'./iconSuccess.svg'} style={{ width: '50px', marginBottom: '30px' }} />
      <span style={{ color: '#ffffff', fontWeight: 500, fontSize: '20px', lineHeight: '30px', textAlign: 'center', marginBottom: '30px' }}>
        {t('registration.successTitle')}
      </span>
      <span style={{ color: '#ffffff', fontWeight: 500, fontSize: '14px', lineHeight: '21px', textAlign: 'center' }}>
        {t('registration.successDescription')}
      </span>
    </div>
  );
  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        backgroundImage: "url('./bg_Introduction.png')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        paddingBottom: '375px',
      }}
    >
      <TopBar isRegistration={true} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '80px',
          paddingBottom: '40px',
        }}
      >
        <img className={'logoImages'} src={'./tamjaiLogo.svg'} />
        <Divider />
        <img className={'logoImages samgorLogo'} src={'./samgorLogo.png'} />
        <Divider />
        <img className={'logoImages'} src={'./artCentreLogo.svg'} />
      </div>

      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '30px',
          lineHeight: '45px',
          textAlign: 'center',
          paddingBottom: '40px',
          margin: '0px 20px',
        }}
      >
        {t('registration.title')}
      </span>

      { show2RoundReg && 
        <div style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '24px',
          textAlign: 'center',
          paddingBottom: '40px',
        }}>
          { isRegisterFinalCall() ?  t('registration.finalCallRegistrationTitle') : t('registration.secondRoundRegistrationTitle') }
        </div>
      }

      {isResultSuccess ? SuccessEl : FormEl}
      <Footer />
    </div>
  );
};
export default Registration;
