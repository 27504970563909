import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './language/index';
import Registration from './Registration';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const TestProvider = (props: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('TestProvider props', props);
    console.log('TestProvider window.locationf', window.location);
    console.log('TestProvider process.env', process.env.REACT_APP_HOMEPAGE_PATH);
    if (window.location.pathname === '/') {
      // navigate('/tjnft');
      // navigate(process.env.REACT_APP_HOMEPAGE_PATH!);
    }
  }, []);
  return <>{props.children}</>;
};

root.render(
  <React.StrictMode>
    <HashRouter>
      {/* <TestProvider> */}
        <Routes>
          <Route path={process.env.REACT_APP_HOMEPAGE_PATH} element={<App />} />
          <Route path={`${process.env.REACT_APP_HOMEPAGE_PATH}/registration`} element={<Registration />} />
        </Routes>
      {/* </TestProvider> */}
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
