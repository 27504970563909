import { Accordion, AccordionSummary, AccordionDetails, withStyles } from '@mui/material';
import cuid from 'cuid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import './FAQs.scss';

interface QuestionAnswer {
  id: string;
  question: string;
  answer: string;
}
const getTranslationQuestionList = (t: any) => {
  let list: Array<QuestionAnswer> = [];
  for (var i = 1; i <= 13; i++) {
    let temp: QuestionAnswer = {
      id: cuid(),
      question: t(`faqs.question${i.toString()}`),
      answer: t(`faqs.question${i.toString()}Answer`),
    };
    list.push(temp);
  }
  return list;
};
const FAQs = () => {
  const { t, i18n } = useTranslation();
  const [questionList, setQuestionList] = useState(getTranslationQuestionList(t));
  const [expandedList, setExpandedList] = useState<Array<string>>([]);
  useEffect(() => {
    setQuestionList(getTranslationQuestionList(t))
  }, [i18n.language]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      id="faqs"
    >
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: '50px',
        }}
      >
        {t('topbar.faqs')}
      </span>
      <div className={'questionList'}>
        {questionList &&
          questionList.length > 0 &&
          questionList.map((el, index) => {
            let isHead = index === 0;
            let isLast = index === questionList.length - 1;
            return (
              <div key={el.id} className="questionList__item">
                <Accordion
                  disableGutters
                  square
                  sx={{
                    background: 'linear-gradient(97.25deg, rgba(54, 19, 63, 1), rgba(67, 69, 91, 0.26))',
                    color: '#ffffff',
                    ...(isHead && {
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',
                    }),
                    ...(isLast && {
                      borderBottomLeftRadius: '20px',
                      borderBottomRightRadius: '20px',
                    }),
                  }}
                  onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                    console.log('expanded,key', expanded, el.id);
                    if (expanded) {
                      setExpandedList((prev) => [...prev, el.id]);
                    } else {
                      setExpandedList((prev) => prev.filter((item) => item !== el.id));
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={expandedList.includes(el.id) ? <img src={'/iconMinus.svg'} /> : <img src={'/iconAdd.svg'} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      fontWeight: 500,
                      fontSize: '24px',
                      lineHeight: '36px',
                      padding: '20px',
                    }}
                  >
                    <span style={{ fontWeight: 700, width: '5%', minWidth: '50px' }}>{(index + 1).toString().padStart(2, '0')}</span>
                    <span style={{ width: '95%' }}>{el.question}</span>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                      padding: '0px 20px',
                    }}
                  >
                    <div style={{ display: 'flex', paddingBottom: '40px' }}>
                      <span style={{ fontWeight: 700, width: '5%', minWidth: '50px' }}></span>
                      <span style={{ width: '90%' }}>{parse(el.answer)}</span>
                      <span style={{ fontWeight: 700, width: '5%', minWidth: '50px' }}></span>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {isLast ? null : (
                  <div
                    style={{
                      width: '99.9%',
                      height: '2px',
                      background: 'linear-gradient(97.25deg, rgba(255, 199, 53, 1),rgba(222, 52, 66, 1))',
                    }}
                  />
                )}
              </div>
            );
          })}
      </div>
      <img src={'/mountain.svg'} className='mountain' />
    </div>
  );
};

export default FAQs;
