import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalesKey } from '../../language';
import './AboutPlanetS.scss';
const AboutPlanetS = () => {
  const { t, i18n } = useTranslation();
  const tamjaiLink = i18n.language === LocalesKey.EN ? 'https://tamjai-intl.com/en/' : 'https://tamjai-intl.com/';
  const hkArtCenterKink = 'http://www.hkac.org.hk';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '100px',
        margin: '0px 10%',
      }}
      id="aboutPlanetS"
    >
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: '50px',
          textAlign: 'center',
        }}
      >
        {t('topbar.aboutPlanetS')}
      </span>

      <div
        className="container"
        style={{
          backgroundImage: "url('/moonWithAstronaut.svg')",
        }}
      >
        <div>
          <img src={'/tamjaiInternationalLogo.png'} style={{ marginBottom: '20px', marginTop: '20px', height: '60px' }} />
          <Link
            href={tamjaiLink}
            style={{
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '30px',
              marginBottom: '30px',
              marginTop: '30px',
              textAlign: 'center',
            }}
          >
            <span>{t('aboutPlanetS.title1')}</span>
          </Link>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '21px',
              marginBottom: '30px',
              textAlign: 'center',
            }}
          >
            {t('aboutPlanetS.description1')}
          </span>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '21px',
              textAlign: 'center',
            }}
          >
            {t('aboutPlanetS.description2')}
          </span>
        </div>

        <div style={{ width: '20%' }}></div>
        <div>
          <img src={'/artCentreLogo.svg'} style={{ marginBottom: '20px', height: '80px', objectFit: 'none' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '30px',
              // marginBottom: '30px',
              textAlign: 'center',
            }}
          >
            {t('aboutPlanetS.beneficiaryOrganisation')}
          </span>
          <Link
            href={hkArtCenterKink}
            style={{
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '30px',
              marginBottom: '30px',
              textAlign: 'center',
            }}
          >
            <span
            // style={{
            //   color: '#ffffff',
            //   fontWeight: 500,
            //   fontSize: '20px',
            //   lineHeight: '30px',
            //   marginBottom: '30px',
            //   textAlign: 'center',
            // }}
            >
              {t('aboutPlanetS.title2')}
            </span>
          </Link>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '21px',
              marginBottom: '30px',
              textAlign: 'center',
            }}
          >
            {t('aboutPlanetS.description3')}
          </span>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '21px',
              marginBottom: '30px',
              textAlign: 'center',
            }}
          >
            {t('aboutPlanetS.description4')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutPlanetS;
