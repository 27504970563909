import Marquee from '../../components/Marquee/Marquee';
import './NftSampleSlider.scss';

const NftSampleSlider = () => {
  return (
    <div style={{ width: '100%', paddingTop: 100, paddingBottom: 110 }}>
      <Marquee gradient={false} speed={100} style={{ paddingBottom: 20, paddingLeft: 100, width: 'calc(100% - 100px)' }}>
        <img
          src={'/nft/sample5.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample6.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample8.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample9.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample10.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample5.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample6.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample8.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample9.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample10.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
      </Marquee>
      <Marquee gradient={false} speed={100}>
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample11.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample12.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample13.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample14.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample5.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample6.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample11.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample12.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample13.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample7.svg'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample14.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample5.png'}
          className={'nftSampleImage'}
        />
        <img
          src={'/nft/sample6.png'}
          className={'nftSampleImage'}
        />
      </Marquee>
    </div>
  );
};
export default NftSampleSlider;
