import React from 'react';
import './App.css';
import TopBar from './components/TopBar/TopBar';
import Introduction from './containers/Introduction/Introduction';
import WhitelistingArrangement from './containers/WhitelistingArrangement/WhitelistingArrangement';
import MintingArrangement from './containers/MintingArrangement/MintingArrangement';
import AuctionRelated from './containers/AuctionRelated/AuctionRelated';
import RoadMap from './containers/RoadMap/RoadMap';
import HolderBenefit from './containers/HolderBenefit/HolderBenefit';
import AboutPlanetS from './containers/AboutPlanetS/AboutPlanetS';
import FAQs from './containers/FAQs/FAQs';
import StoryBackground from './containers/StoryBackground/StoryBackground';
import NftSampleSlider from './containers/NftSampleSlider/NftSampleSlider';
import Footer from './components/Footer/Footer';
import { isPhase3, isPhase4, isPhase5, isPhase6 } from './utils/checkPhase';
function App() {
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <TopBar isRegistration={false} />
      <Introduction />
      <StoryBackground />
      <div
        style={{
          backgroundImage: "url('/bg_long.png')",
          backgroundColor: '#260145',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
        }}
      >
        <NftSampleSlider />
        {isPhase3() || isPhase4() || isPhase5() || isPhase6() ? null : <WhitelistingArrangement />}
        <MintingArrangement />
        {isPhase5() || isPhase6() ? <AuctionRelated /> : null}
        <RoadMap />
        <HolderBenefit />
        <AboutPlanetS />
        <FAQs />
        <Footer />
      </div>
    </div>
  );
}

export default App;
