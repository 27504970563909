import { useTranslation } from 'react-i18next';
import Timeline from '../../components/Timeline/Timeline';

const RoadMap = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '100px',
        color: '#ffffff',
      }}
      id="roadMap"
    >
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: 50,
        }}
      >
        {t('topbar.roadMap')}
      </span>
      <div
        style={{
          position: 'relative',
          backgroundImage: "url('/bubble.svg')",
          backgroundSize: 'contain',
          width: '200px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ width: '60%', fontWeight: 600, fontSize: '26px', lineHeight: '31.2px', textAlign: 'center', textTransform: 'uppercase' }}>
          {t('roadMap.title1')}
        </span>
        <div
          style={{
            height: '2px',
            width: '60%',
            background: 'linear-gradient(97.25deg, rgba(255, 241, 170, 0.53), rgba(255, 245, 0, 1),rgba(255, 214, 0, 0.3))',
          }}
        ></div>
      </div>
      <Timeline
        list={[
          {
            leftText: t('roadMap.yr1Event1Text'),
            rightText: t('roadMap.yr1Event1Description'),
          },
          {
            leftText: t('roadMap.yr1Event2Text'),
            rightText: t('roadMap.yr1Event2Description'),
          },
          {
            leftText: t('roadMap.yr1Event3Text'),
            rightText: t('roadMap.yr1Event3Description'),
          },
          {
            leftText: t('roadMap.yr1Event4Text'),
            rightText: t('roadMap.yr1Event4Description'),
          },
          {
            leftText: t('roadMap.yr1Event5Text'),
            rightText: t('roadMap.yr1Event5Description'),
          },
          {
            leftText: t('roadMap.yr1Event6Text'),
            rightText: t('roadMap.yr1Event6Description'),
          },
          {
            leftText: t('roadMap.yr1Event7Text'),
            rightText: t('roadMap.yr1Event7Description'),
          },
          {
            leftText: t('roadMap.yr1Event8Text'),
            rightText: t('roadMap.yr1Event8Description'),
          },
        ]}
      />

      <div
        style={{
          position: 'relative',
          backgroundImage: "url('/bubble.svg')",
          backgroundSize: 'contain',
          width: '200px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ width: '60%', fontWeight: 600, fontSize: '26px', lineHeight: '31.2px', textAlign: 'center', textTransform: 'uppercase' }}>
          {t('roadMap.title2')}
        </span>
        <div
          style={{
            height: '2px',
            width: '60%',
            background: 'linear-gradient(97.25deg, rgba(255, 241, 170, 0.53), rgba(255, 245, 0, 1),rgba(255, 214, 0, 0.3))',
          }}
        ></div>
      </div>

      <Timeline
        list={[
          {
            leftText: t('roadMap.yr2Event1Text'),
            rightText: t('roadMap.yr2Event1Description'),
          },
          {
            leftText: t('roadMap.yr2Event2Text'),
            rightText: t('roadMap.yr2Event2Description'),
          },
        ]}
      />
    </div>
  );
};

export default RoadMap;
