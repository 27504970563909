import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { isPhase5, isPhase6 } from '../../utils/checkPhase';
import './HolderBenefit.scss';
const HolderBenefit = () => {
  const { t } = useTranslation();

  const showPhase5And6El = isPhase5() || isPhase6();
  const showPhase6Img = isPhase6();
  const phase5And6El = (
    <>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: '50px',
          margin: '0px 10%',
          textAlign: 'center',
        }}
      >
        {t('holderBenefit.title1')}
      </span>

      <div className={'quotaRelative'}>
        <img
          src={showPhase6Img ? '/auctionRelatedImg3_phase6.svg' : '/celebrityJehJeh.jpeg'}
          style={{ marginRight: '15px' }}
          className="celebrityImg"
        />
        <img
          //
          src={showPhase6Img ? '/auctionRelatedImg4_phase6.svg' : '/funkySamGor.jpeg'}
          // style={{ width: '175px', marginBottom: '30px', borderRadius: '20px' }}
          className="celebrityImg"
        />
        {/* <div
          className={'quota'}
          style={{
            backgroundImage: "url('/chatBubble.png')",
          }}
        >
          {t('holderBenefit.quotaOnly')}
        </div> */}
      </div>

      <div
        style={{
          display: 'flex',
          // margin: '0px 10% 30px',
          margin: '0px 0px 30px',
          padding: '0px 20px 0px',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box className="biggerTransparentContainer">
          <img src={'/iconNoodles.svg'} style={{ width: '86px', marginBottom: '27px' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            dangerouslySetInnerHTML={{
              __html: t('holderBenefit.description1'),
            }}
          />
        </Box>
        <Box className="biggerTransparentContainer">
          <img src={'/iconParty.svg'} style={{ width: '86px', marginBottom: '27px' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            dangerouslySetInnerHTML={{
              __html: t('holderBenefit.description7'),
            }}
          />
        </Box>
        <Box className="biggerTransparentContainer">
          <img src={'/iconReward.svg'} style={{ width: '86px', marginBottom: '27px' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            dangerouslySetInnerHTML={{
              __html: t('holderBenefit.description2'),
            }}
          />
        </Box>
        <Box className="biggerTransparentContainer">
          <img src={'/iconHands.svg'} style={{ width: '86px', marginBottom: '27px' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            dangerouslySetInnerHTML={{
              __html: t('holderBenefit.description3'),
            }}
          />
        </Box>
      </div>

      <div
        style={{
          display: 'flex',
          margin: '0px 5% 130px',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box className="smallerTransparentContainer">
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description4'),
                }}
              />
            </li>
          </ul>
        </Box>
        <Box className="smallerTransparentContainer">
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description5'),
                }}
              />
            </li>
          </ul>
        </Box>
        <Box className="smallerTransparentContainer">
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description6'),
                }}
              />
            </li>
          </ul>
        </Box>
        {/* <Box className="smallerTransparentContainer">
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description7'),
                }}
              />
            </li>
          </ul>
        </Box> */}
        <Box className="smallerTransparentContainer">
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description15'),
                }}
              />
            </li>
          </ul>
        </Box>
        <span
          style={{
            color: '#ffffff',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            // marginTop: '-140px',
            marginBottom: '40px',
            padding: '0px 10%',
            textAlign: 'center',
          }}
        >
          {t('holderBenefit.tnc2')}
        </span>
        {/* <span
          style={{
            color: '#ffffff',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            // marginTop: '-140px',
            marginBottom: '40px',
            padding: '0px 10%',
            textAlign: 'center',
          }}
        >
          {' '}
          {t('holderBenefit.tnc2Content')}
        </span> */}
      </div>
    </>
  );
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="holderBenefit">
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: '50px',
        }}
      >
        {t('topbar.holderBenefit')}
      </span>
      {showPhase5And6El ? phase5And6El : null}
      {/*  */}
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: '80px',
          margin: '0px 10%',
          textAlign: 'center',
        }}
      >
        {t('holderBenefit.title2')}
      </span>

      <Grid
        style={{ display: 'flex', marginBottom: '50px' }}
        className="secondPartImgContainer"
        alignItems="center"
        // width={'100%'}
      >
        <img src={'/nft/sample6_withBg.png'} />
        <img src={'/nft/sample13_withBg.png'} />
        <img src={'/nft/sample9_withBg.png'} />
      </Grid>

      <div
        style={{
          display: 'flex',
          margin: '0px 10% 30px',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box className="biggerTransparentContainer">
          <img src={'/iconReward.svg'} style={{ width: '86px', marginBottom: '27px' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            dangerouslySetInnerHTML={{
              __html: t('holderBenefit.description8'),
            }}
          />
        </Box>
        <Box className="biggerTransparentContainer">
          <img src={'/iconNoodles.svg'} style={{ width: '86px', marginBottom: '27px' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            dangerouslySetInnerHTML={{
              __html: t('holderBenefit.description9'),
            }}
          />
        </Box>
        <Box className="biggerTransparentContainer">
          <img src={'/iconHands.svg'} style={{ width: '86px', marginBottom: '27px' }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            dangerouslySetInnerHTML={{
              __html: t('holderBenefit.description10'),
            }}
          />
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          margin: '0px 5% 130px',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box
          // sx={{
          //   ...smallerTransparentContainerStyle,
          //   marginRight: '24px',
          //   marginBottom: '24px',
          // }}
          className="smallerTransparentContainer"
        >
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description11'),
                }}
              />
            </li>
          </ul>
        </Box>
        <Box
          // sx={{
          //   ...smallerTransparentContainerStyle,
          //   marginRight: '24px',
          //   marginBottom: '24px',
          // }}
          className="smallerTransparentContainer"
        >
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description12'),
                }}
              />
            </li>
          </ul>
        </Box>
        <Box
          // sx={{
          //   ...smallerTransparentContainerStyle,
          //   marginRight: '24px',
          //   marginBottom: '24px',
          // }}
          className="smallerTransparentContainer"
        >
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description13'),
                }}
              />
            </li>
          </ul>
        </Box>
        <Box
          // sx={{
          //   ...smallerTransparentContainerStyle,
          //   marginRight: '24px',
          //   marginBottom: '24px',
          // }}
          className="smallerTransparentContainer"
        >
          <ul>
            <li>
              <span
                style={{
                  color: '#ffffff',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('holderBenefit.description14'),
                }}
              />
            </li>
          </ul>
        </Box>
      </div>
      <div
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          marginTop: '-140px',
          marginBottom: '140px',
        }}
      >
        {t('holderBenefit.tnc')}
      </div>
      <div
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          margin: '-120px 10% 50px',
        }}
      >
        {t('holderBenefit.tncContent')}
      </div>
    </div>
  );
};

export default HolderBenefit;

// const phase5And6El = (
//   <>
//     <span
//       style={{
//         color: '#ffffff',
//         fontWeight: 700,
//         fontSize: '36px',
//         lineHeight: '54px',
//         paddingBottom: '50px',
//         margin: '0px 10%',
//         textAlign: 'center',
//       }}
//     >
//       {t('holderBenefit.title1')}
//     </span>

//     <div className={'quotaRelative'}>
//       <img
//         src={showPhase6Img ? '/auctionRelatedImg3_phase6.svg' : '/auctionRelatedImg1.svg'}
//         style={{ width: '200px', marginBottom: '30px', marginRight: '15px' }}
//       />
//       <img src={showPhase6Img ? '/auctionRelatedImg4_phase6.svg' : '/auctionRelatedImg2.svg'} style={{ width: '200px', marginBottom: '30px' }} />
//       <div
//         className={'quota'}
//         style={{
//           backgroundImage: "url('/chatBubble.png')",
//         }}
//       >
//         {t('holderBenefit.quotaOnly')}
//       </div>
//     </div>

//     <div
//       style={{
//         display: 'flex',
//         // margin: '0px 10% 30px',
//         margin: '0px 0px 30px',
//         padding: '0px 20px 0px',
//         justifyContent: 'center',
//         flexWrap: 'wrap',
//       }}
//     >
//       <Box className="biggerTransparentContainer">
//         <img src={'/iconNoodles.svg'} style={{ width: '86px', marginBottom: '27px' }} />
//         <span
//           style={{
//             color: '#ffffff',
//             fontWeight: 400,
//             fontSize: '16px',
//             lineHeight: '24px',
//           }}
//           dangerouslySetInnerHTML={{
//             __html: t('holderBenefit.description1'),
//           }}
//         />
//       </Box>
//       <Box className="biggerTransparentContainer">
//         <img src={'/iconReward.svg'} style={{ width: '86px', marginBottom: '27px' }} />
//         <span
//           style={{
//             color: '#ffffff',
//             fontWeight: 400,
//             fontSize: '16px',
//             lineHeight: '24px',
//           }}
//           dangerouslySetInnerHTML={{
//             __html: t('holderBenefit.description2'),
//           }}
//         />
//       </Box>
//       <Box className="biggerTransparentContainer">
//         <img src={'/iconHands.svg'} style={{ width: '86px', marginBottom: '27px' }} />
//         <span
//           style={{
//             color: '#ffffff',
//             fontWeight: 400,
//             fontSize: '16px',
//             lineHeight: '24px',
//           }}
//           dangerouslySetInnerHTML={{
//             __html: t('holderBenefit.description3'),
//           }}
//         />
//       </Box>
//     </div>

//     <div
//       style={{
//         display: 'flex',
//         margin: '0px 5% 130px',
//         justifyContent: 'center',
//         flexWrap: 'wrap',
//       }}
//     >
//       <Box
//         // sx={{
//         //   ...smallerTransparentContainerStyle,
//         //   marginRight: '24px',
//         //   marginBottom: '24px',
//         // }}
//         className="smallerTransparentContainer"
//       >
//         <ul>
//           <li>
//             <span
//               style={{
//                 color: '#ffffff',
//                 fontWeight: 400,
//                 fontSize: '16px',
//                 lineHeight: '24px',
//               }}
//               dangerouslySetInnerHTML={{
//                 __html: t('holderBenefit.description4'),
//               }}
//             />
//           </li>
//         </ul>
//       </Box>
//       <Box
//         // sx={{
//         //   ...smallerTransparentContainerStyle,
//         //   marginRight: '24px',
//         //   marginBottom: '24px',
//         // }}
//         className="smallerTransparentContainer"
//       >
//         <ul>
//           <li>
//             <span
//               style={{
//                 color: '#ffffff',
//                 fontWeight: 400,
//                 fontSize: '16px',
//                 lineHeight: '24px',
//               }}
//               dangerouslySetInnerHTML={{
//                 __html: t('holderBenefit.description5'),
//               }}
//             />
//           </li>
//         </ul>
//       </Box>
//       <Box
//         // sx={{
//         //   ...smallerTransparentContainerStyle,
//         //   marginRight: '24px',
//         //   marginBottom: '24px',
//         // }}
//         className="smallerTransparentContainer"
//       >
//         <ul>
//           <li>
//             <span
//               style={{
//                 color: '#ffffff',
//                 fontWeight: 400,
//                 fontSize: '16px',
//                 lineHeight: '24px',
//               }}
//               dangerouslySetInnerHTML={{
//                 __html: t('holderBenefit.description6'),
//               }}
//             />
//           </li>
//         </ul>
//       </Box>
//       <Box
//         // sx={{
//         //   ...smallerTransparentContainerStyle,
//         //   marginRight: '24px',
//         //   marginBottom: '24px',
//         // }}
//         className="smallerTransparentContainer"
//       >
//         <ul>
//           <li>
//             <span
//               style={{
//                 color: '#ffffff',
//                 fontWeight: 400,
//                 fontSize: '16px',
//                 lineHeight: '24px',
//               }}
//               dangerouslySetInnerHTML={{
//                 __html: t('holderBenefit.description7'),
//               }}
//             />
//           </li>
//         </ul>
//       </Box>
//     </div>
//   </>
// );
