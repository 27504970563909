import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import zhHk from './locales/zh-hk.json';

enum LocalesKey {
  EN = 'en',
  ZH_HK = 'zhHk',
}

const resources = {
  [LocalesKey.EN]: {
    translation: {
      ...en,
    },
  },
  [LocalesKey.ZH_HK]: {
    translation: {
      ...zhHk,
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'sessionStorage'],

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      lookupSessionStorage: 'lang',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['cookie'],
    },
    fallbackLng: 'zhHk',
    resources,
    // lng: 'zhHk', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { LocalesKey };
