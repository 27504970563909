import { Button, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './WhitelistingArrangement.scss';
import { isPhase2 } from '../../utils/checkPhase';

const WhitelistingArrangement = () => {
  const { t } = useTranslation();
  const showPhase2 = isPhase2();

  const Phase2 = (
    <>
      <img src={'/igStory.svg'} className={'igImage'} alt="ig" />

      <div className={'whatsMore'}>
        <div className={'whatsMoreContent'}>
          <span style={{ color: '#ffffff', fontWeight: 700, fontSize: '24px', lineHeight: '36px', paddingBottom: 50 }}>
            {t('whitelistingArrangement.subTitle2')}
          </span>
          <span
            style={{ color: '#ffffff', fontWeight: 400, fontSize: '18px', lineHeight: '27px', paddingBottom: 50 }}
            dangerouslySetInnerHTML={{ __html: t('whitelistingArrangement.description3') }}
          />
        </div>
        <div
          className={'jehJeh'}
          style={{
            backgroundImage: "url('/chatBubble.png')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <span style={{ color: '#ffffff', fontWeight: 700, fontSize: '18px', lineHeight: '27px', margin: '0px 10%', textAlign: 'center' }}>
            {t('whitelistingArrangement.description4')} 😍
          </span>
        </div>
      </div>
    </>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '100px',
      }}
      id="whitelistingArrangement"
    >
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: 50,
          textAlign: 'center',
        }}
      >
        {t('topbar.whitelistingArrangement')}
      </span>

      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '36px',
          paddingBottom: 50,
        }}
      >
        {t('whitelistingArrangement.subTitle1')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '27px',
          paddingBottom: 50,
          margin: '0px 10%',
          textAlign: 'center',
        }}
        dangerouslySetInnerHTML={{
          __html: t('whitelistingArrangement.description1'),
        }}
      />
      <Link href="https://instagram.com/tamjai_souperhero?igshid=YmMyMTA2M2Y=" target={'_blank'}>
        <Button
          disableRipple
          size={'large'}
          sx={{
            backgroundColor: 'rgba(148, 17, 28, 1)',
            color: '#ffffff',
            borderRadius: '40px',
            textTransform: 'capitalize',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontWeight: 700,
            marginBottom: '50px',
            ':hover': { bgcolor: 'rgba(148, 17, 28, 1)', color: '#ffffff' },
          }}
          startIcon={<img src={'/igLogo_blackWhite.svg'} style={{ width: '20px' }} alt="ig logo" />}
        >
          {'@tamjai_souperhero'}
        </Button>
      </Link>
      {showPhase2 && Phase2}
    </div>
  );
};

export default WhitelistingArrangement;
