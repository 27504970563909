import axios from 'axios';
const apiDomain = process.env.REACT_APP_API_DOMAIN;

const nftRegistration = async (postData: any) => {
  try {
    const response = await axios.post(`${apiDomain}/register`, postData);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const checkEmailIsExist = async (postData: any) => {
  try {
    const response = await axios.post(`${apiDomain}/check`, postData);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export { nftRegistration, checkEmailIsExist };
