import { Button, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Divider from '../../components/Divider/Divider';
import './Introduction.scss';

const Introduction = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        backgroundImage: "url('/bg_Introduction.png')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-64px',
      }}
      id="introduction"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '150px',
          paddingBottom: '40px',
        }}
      >
        <img className={'logoImages'} src={'/tamjaiLogo.svg'} />
        <Divider />
        <img className={'logoImages samgorLogo'} src={'/samgorLogo.png'} />
        <Divider />
        <img className={'logoImages'} src={'/artCentreLogo.svg'} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <span className={'title2'}>
          {t('introduction.title2')}
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <span
          style={{
            color: '#ffffff',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '30px',
            paddingBottom: 20,
            margin: '0px 20px',
          }}
        >
          {t('introduction.title')}
        </span>
        <span
          style={{
            color: '#ffffff',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            paddingBottom: 40,
            margin: '0px 10%',
          }}
        >
          {t('introduction.description')}
        </span>
        <Link
          href="https://instagram.com/tamjai_souperhero?igshid=YmMyMTA2M2Y=" 
          target={'_blank'}
        >
          <Button
            disableRipple
            size={'large'}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: 'rgba(148, 17, 28, 1)',
              borderRadius: '40px',
              textTransform: 'lowercase',
              paddingLeft: '20px',
              paddingRight: '20px',
              fontWeight: 700,
              ':hover': {
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                color: 'rgba(148, 17, 28, 1)',
              },
            }}
            startIcon={<img src={'/igLogo.svg'} style={{ width: '20px' }} />}
          >
            {'@tamjai_souperhero'}
          </Button>
        </Link>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={'/nft/sample1.png'} className="nftImage" />
        <img src={'/nft/sample2.png'} className="nftImage" />
        <img src={'/nft/sample3.png'} className="nftImage" />
        <img src={'/nft/sample4.png'} className="nftImage" />
      </div>
    </div>
  );
};

export default Introduction;
