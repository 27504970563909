import { Button, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { isPhase6 } from '../../utils/checkPhase';
import './AuctionRelated.scss';

const AuctionRelated = () => {
  const { t } = useTranslation();
  const showBidNow = isPhase6();
  // const bidNowUrl = 'http://google.com';
  const exploreMoreLink = 'https://marketplace.artzioneer.com/';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '100px',
      }}
      id="auctionRelated"
    >
      <span style={{ color: '#ffffff', fontWeight: 700, fontSize: '36px', lineHeight: '54px', paddingBottom: '50px', textAlign: 'center' }}>
        {t('topbar.auctionRelated')}
      </span>
      <span style={{ color: '#ffffff', fontWeight: 400, fontSize: '24px', lineHeight: '36px', padding: '0px 10% 50px', textAlign: 'center' }}>
        {t('auctionRelated.titleDescription')}
      </span>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '50%',
          marginBottom: '40px',
          //  margin: '0px 20% 40px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={showBidNow ? '/auctionRelatedImg3_phase6.svg' : '/celebrityJehJeh.jpeg'} className="auctionImg" />
          <span className="avatarTitle">{t('auctionRelated.avatarTitle1')}</span>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              // paddingBottom: 30,
              margin: '0px 10%',
              textAlign: 'center',
              height: '78px',
            }}
          >
            {t('auctionRelated.avatarTitle1DesignBy')}
          </span>
          {/* <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              paddingBottom: '30px',
              margin: '0px 10%',
              textAlign: 'center',
            }}
          >
            {t('auctionRelated.avatarDescription1')}
          </span> */}
          {/* <img src={'/samgorLogo.svg'} style={{ marginBottom: '10px' }} />
          <span style={{ color: '#ffffff', fontWeight: 700, fontSize: '48px', lineHeight: '72px' }}>10</span>
          <span style={{ color: 'rgba(255, 255, 255, 0.5)', fontWeight: 500, fontSize: '24px', lineHeight: '36px' }}>
            {t('auctionRelated.totalTokens')}
          </span> */}
        </div>
        <div style={{ flex: '0 0 20px' }}></div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={showBidNow ? '/auctionRelatedImg4_phase6.svg' : '/funkySamGor.jpeg'} className="auctionImg" />
          <span className="avatarTitle">{t('auctionRelated.avatarTitle2')}</span>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              // paddingBottom: 30,
              margin: '0px 10%',
              textAlign: 'center',
              height: '78px',
            }}
          >
            {t('auctionRelated.avatarTitle2DesignBy')}
          </span>
          {/* <span
            style={{
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '24px',
              paddingBottom: '30px',
              margin: '0px 10%',
              textAlign: 'center',
            }}
          >
            {t('auctionRelated.avatarDescription2')}
          </span> */}
          {/* <img src={'/tamjaiLogo.svg'} style={{ marginBottom: 10 }} />
          <span style={{ color: '#ffffff', fontWeight: 700, fontSize: '48px', lineHeight: '72px' }}>10</span>
          <span style={{ color: 'rgba(255, 255, 255, 0.5)', fontWeight: 500, fontSize: '24px', lineHeight: '36px' }}>
            {t('auctionRelated.totalTokens')}
          </span> */}
        </div>
      </div>

      {/* <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '27px',
          textAlign: 'center',
          margin: '0px 10%',
          paddingBottom: '20px',
          paddingTop: '20px',
        }}
      >
        {t('auctionRelated.content1')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '27px',
          textAlign: 'center',
          margin: '0px 10%',
          paddingBottom: '20px',
        }}
      >
        {t('auctionRelated.content2')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '27px',
          textAlign: 'center',
          margin: '0px 10%',
          paddingBottom: '20px',
        }}
      >
        {t('auctionRelated.content3')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '27px',
          textAlign: 'center',
          margin: '0px 10%',
          paddingBottom: '20px',
        }}
      >
        {t('auctionRelated.content4')}
      </span>
      <span
        style={{
          color: '#ffffff',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '27px',
          textAlign: 'center',
          margin: '0px 10%',
          paddingBottom: '20px',
        }}
      >
        {t('auctionRelated.content5')}
      </span> */}
      {/* {showBidNow ? (
        <Link href={bidNowUrl} sx={{ textDecoration: 'none' }}>
          <Button
            disableRipple
            size={'large'}
            sx={{
              backgroundColor: '#94111C',
              color: '#ffffff',
              borderRadius: '40px',
              textTransform: 'capitalize',
              minWidth: '100px',
              cursor: 'pointer',
              fontWeight: 700,
              paddingLeft: '20px',
              paddingRight: '20px',
              ':hover': {
                bgcolor: '#94111C',
                color: '#ffffff',
              },
            }}
            endIcon={<img src={'/iconRight.svg'} style={{ width: '20px' }} />}
          >
            {t('auctionRelated.bidNow')}
          </Button>
        </Link>
      ) : (
        <Link href={bidNowUrl} sx={{ pointerEvents: 'none', cursor: 'not-allowed', textDecoration: 'none' }}>
          <Button
            disableRipple
            size={'large'}
            sx={{
              backgroundColor: '#777777',
              color: '#ffffff80',
              borderRadius: '40px',
              textTransform: 'capitalize',
              minWidth: '100px',
              fontWeight: 700,
              paddingLeft: '20px',
              paddingRight: '20px',
              ':hover': {
                bgcolor: '#777777',
                color: '#ffffff80',
              },
            }}
            endIcon={<img src={'/iconRight.svg'} style={{ width: '20px' }} />}
          >
            {t('auctionRelated.bidSoon')}
          </Button>
        </Link>
      )} */}

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span style={{ color: '#ffffff', fontWeight: 700, fontSize: '36px', lineHeight: '54px', paddingBottom: '50px' }}>
          {t('auctionRelated.auctionArrangement')}
        </span>
        <span style={{ color: '#ffffff', fontWeight: 400, fontSize: '24px', lineHeight: '36px', textAlign: 'center', marginBottom: '20px' }}>
          {t('auctionRelated.startBiddingDate')}
        </span>
        <span style={{ color: '#ffffff', fontWeight: 400, fontSize: '24px', lineHeight: '36px', textAlign: 'center', marginBottom: '20px' }}>
          {t('auctionRelated.platform')}
        </span>
        {/* <Link href={exploreMoreLink} sx={{ textDecoration: 'none' }}>
          <Button
            disableRipple
            size={'large'}
            sx={{
              backgroundColor: '#94111C',
              color: '#ffffff',
              borderRadius: '40px',
              textTransform: 'capitalize',
              minWidth: '100px',
              cursor: 'pointer',
              fontWeight: 700,
              paddingLeft: '20px',
              paddingRight: '20px',
              ':hover': {
                bgcolor: '#94111C',
                color: '#ffffff',
              },
            }}
            endIcon={<img src={'/iconRight.svg'} style={{ width: '20px' }} />}
          >
            {t('auctionRelated.exploreNow')}
          </Button>
        </Link> */}
      </div>
    </div>
  );
};

export default AuctionRelated;
