import { Button, Link } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isPhase3, isPhase4, isPhase5, isRegisterFinalCall, getMintNowURL } from '../../utils/checkPhase';
import './MintingArrangement.scss';

const MintingArrangement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mintNowUrl = getMintNowURL();
  // const showMintNow = new Date() < new Date('2022/06/17 10:00') && (isPhase3() || isPhase4() || isPhase5()); //for testing
  const showMintNow = new Date() < new Date('2022/06/19 10:00') && (isPhase3() || isPhase4() || isPhase5());
  const gradientBorderStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // padding: '40px 20px',
    textAlign: 'center',
    width: '270px',
    height: '270px',
    position: 'relative',
    zIndex: 0,
    margin: '0px 10px 20px',
    ':before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      inset: 0,
      padding: '1px',
      borderRadius: '20px',
      background: 'linear-gradient(to bottom, rgba(255, 199, 53, 1), rgba(148, 16, 27, 1))',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '100px',
        margin: '0px 15px',
      }}
      id="mintingArrangement"
    >
      <span
        style={{
          color: '#ffffff',
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '54px',
          paddingBottom: 50,
          textAlign: 'center',
        }}
      >
        {t('topbar.mintingArrangement')}
      </span>
      <div className={'mintingArrangementContainer'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 15px 0px 0px',
          }}
        >
          <img src={'/nft/sample10_noBorder.png'} className={'sampleNFTImage'} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '36px',
              paddingBottom: 10,
              textAlign: 'center',
            }}
          >
            {t('mintingArrangement.samGorSouperHeroes')}
          </span>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              // paddingBottom: 30,
              margin: '0px 10%',
              textAlign: 'center',
              height: '78px',
            }}
          >
            {t('mintingArrangement.samGorSouperHeroesDesc')}
          </span>
          <img src={'/samgorLogo.svg'} style={{ marginBottom: 10 }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: '48px',
              lineHeight: '72px',
            }}
          >
            5,000
          </span>
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '36px',
            }}
          >
            {t('mintingArrangement.totalTokens')}
          </span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 0px 0px 15px',
          }}
        >
          <img src={'/nft/sample11_noBorder.png'} className={'sampleNFTImage'} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '36px',
              paddingBottom: 10,
              textAlign: 'center',
            }}
          >
            {t('mintingArrangement.tamjaiSouperHeroes')}
          </span>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '24px',
              // paddingBottom: 30,
              margin: '0px 10%',
              textAlign: 'center',
              height: '78px',
            }}
          >
            {t('mintingArrangement.tamjaiSouperHeroesDesc')}
          </span>
          <img src={'/tamjaiLogo.svg'} style={{ marginBottom: 10 }} />
          <span
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: '48px',
              lineHeight: '72px',
            }}
          >
            5,000
          </span>
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '36px',
            }}
          >
            {t('mintingArrangement.totalTokens')}
          </span>
        </div>
      </div>

      <div className={'mintingArrangementDate'}>
        <div className="mintingArrangementDate__item">
          <div
            className="mintingArrangementDate__datetime"
            dangerouslySetInnerHTML={{
              __html: t('mintingArrangement.preSaleTime'),
            }}
          />
          <span className="text">{t('mintingArrangement.preSale')}</span>
        </div>
        <div className="mintingArrangementDate__item">
          <div
            className="mintingArrangementDate__datetime"
            dangerouslySetInnerHTML={{
              __html: t('mintingArrangement.publicMintDateTime'),
            }}
          />
          <span className="text">{t('mintingArrangement.publicMintDate')}</span>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: '50px',
          width: '100%',
        }}
      >
        <Box sx={gradientBorderStyle}>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: '50px',
              lineHeight: '75px',
            }}
          >
            4%
          </span>
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '36px',
            }}
          >
            {t('mintingArrangement.tamjaiInternationalStaffFd')}
          </span>
        </Box>
        <Box sx={gradientBorderStyle}>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: '50px',
              lineHeight: '75px',
            }}
          >
            10%
          </span>
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '36px',
            }}
          >
            {t('mintingArrangement.whiteList')}
          </span>
        </Box>
        <Box sx={gradientBorderStyle}>
          <span
            style={{
              color: '#ffffff',
              fontWeight: 700,
              fontSize: '50px',
              lineHeight: '75px',
            }}
          >
            86%
          </span>
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '36px',
            }}
          >
            {t('mintingArrangement.publicSale')}
          </span>
        </Box>
      </div>
      <>
        {
          showMintNow ? (
            <Link
              href={
                isPhase3() || isPhase4()
                  ? isRegisterFinalCall()
                    ? process.env.REACT_APP_REGISTER_NOW_FINAL_URL
                    : process.env.REACT_APP_REGISTER_NOW_PUBLIC_URL
                  : mintNowUrl
              }
              sx={{ textDecoration: 'none' }}
            >
              <Button
                disableRipple
                size={'large'}
                sx={{
                  backgroundColor: '#94111C',
                  color: '#ffffff',
                  borderRadius: '40px',
                  textTransform: 'capitalize',
                  minWidth: '100px',
                  cursor: 'pointer',
                  fontWeight: 700,
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  ':hover': {
                    bgcolor: '#94111C',
                    color: '#ffffff',
                  },
                }}
                endIcon={<img src={'/iconRight.svg'} style={{ width: '20px' }} />}
              >
                {isPhase3() || isPhase4() ? t('topbar.registerNow') : t('topbar.mintNow')}
              </Button>
            </Link>
          ) : null
          //  (
          //   <Link href={mintNowUrl} sx={{ pointerEvents: 'none', cursor: 'not-allowed', textDecoration: 'none' }}>
          //     <Button
          //       disableRipple
          //       size={'large'}
          //       sx={{
          //         backgroundColor: '#777777',
          //         color: '#ffffff80',
          //         borderRadius: '40px',
          //         textTransform: 'capitalize',
          //         minWidth: '100px',
          //         fontWeight: 700,
          //         paddingLeft: '20px',
          //         paddingRight: '20px',
          //         ':hover': {
          //           bgcolor: '#777777',
          //           color: '#ffffff80',
          //         },
          //       }}
          //       endIcon={<img src={'/iconRight.svg'} style={{ width: '20px' }} />}
          //     >
          //       {t('topbar.mintSoon')}
          //     </Button>
          //   </Link>
          // )
        }
      </>
    </div>
  );
};

export default MintingArrangement;
