// import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import './Footer.scss';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const footerLink = [
        { path: i18n.language === 'en' ? 'https://tamjaimixian.com/en/terms-and-conditions/' : 'https://tamjaimixian.com/%e6%a2%9d%e6%ac%be%e5%92%8c%e7%b4%b0%e5%89%87/', displayName: t('footer.terms')},
        { path: i18n.language === 'en' ? 'https://tamjaimixian.com/en/privacy-policy/' : 'https://tamjaimixian.com/%e7%a7%81%e9%9a%b1%e6%94%bf%e7%ad%96/', displayName: t('footer.privacy')},      
    ]
  return (
    <div className='footer'>
        <div className='footer__copyright'>
            { t('footer.copyright', { year: new Date().getFullYear() }) } 
        </div>
        <div>
        {footerLink &&
            footerLink.map((el, index) => (
                <Link 
                    key={index}
                    href={el.path}
                    target="_blank" 
                    rel="noopener" 
                    className='footer__url'
                >
                    { el.displayName }
                </Link>
            ))}
        </div>
    </div>
  );
};
export default Footer;
